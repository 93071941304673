/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Slide,
  MenuItem,
  Popover,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "i18n";
import { InView } from "react-intersection-observer";
import { useRouter } from "next/router";
// import { useSession } from "store/Session";
import routes from "routes";
import withMainLayout from "hocs/withMainLayout";
import { VolumeOff, VolumeUp } from "@material-ui/icons";
import { Carousel } from "react-responsive-carousel";
import { useResource } from "hooks";
// import Countdown from "react-countdown";
import APIEndpoints from "APIEndpoints";
import { useDialogs } from "store/Dialogs";
// import Link from "next/link";

// landing video
import landingVideo from "assets/landing.mp4";
import howToPlayVideo from "assets/how-to-play.mp4";
// roadmap
import roadmapDesktop from "assets/landing/roadmap-desktop.png";
import roadmapMobile from "assets/landing/roadmap-mobile.png";
import crowdImage from "assets/landing/crowd.png";
import discordIcon from "assets/social-media/discord.svg";
import BonusSystem from "assets/landing/bonus-system.png";
import { useSession } from "store/Session";
// import ClosedGame from "components/Dialogs/ClosedGame";

const useStyles = makeStyles((theme) => ({
  hero: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "100vw",
    margin: 0,
    padding: 0,
    "& > div": {
      "&:first-child": {
        backgroundColor: "#010001",
        minHeight: "calc(100vh - 80px)",
      },
      // "&:second-child": {
      //   minHeight: "calc(100vh - 80px)",
      //   backgroundColor: "#010001",
      // },
      paddingLeft: "4rem",
      paddingRight: "4rem",
    },
    "& > div:not(:first-child)": {
      background: "linear-gradient(360deg, #010001 0%, #810000 20%, #010001)",
      textAlign: "center",
      color: theme.custom.color.white,
    },
  },
  landingInfo: {
    color: theme.custom.color.white,
  },
  celebritiesPacksContainer: {
    "& img": {
      position: "relative",
    },
    "& img:nth-child(1)": {
      left: "15%",
    },
    "& img:nth-child(2)": {
      left: "5%",
    },
    "& img:nth-child(3)": {
      left: "-5%",
    },
    "& img:nth-child(4)": {
      left: "-15%",
    },
  },
  roadmap: {
    textAlign: "center",
  },
  latest: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    textAlign: "center",
  },
  roadmapLabel: {
    position: "absolute",
    "& label": {
      fontFamily: "Poppins",
      fontSize: "calc(7.5px + (15 - 7.5) * (100vw - 300px) / 1500)",
    },
  },
  crowdContainer: {
    backgroundImage: `url('${crowdImage.src}')`,
    width: "calc(100% + 8rem)",
    backgroundRepeatX: "repeat",
    backgroundRepeatY: "no-repeat",
    margin: "-4rem",
    height: 305,
    marginTop: "8rem",
  },
  // countdownText: {
  //   display: "flex",
  //   color: theme.custom.color.white,
  //   flexDirection: "column",
  //   alignItems: "center",
  //   [theme.breakpoints.down("sm")]: {
  //     margin: theme.spacing(2),
  //   },
  // },
  // countdownNumbers: {
  //   display: "flex",
  //   justifyContent: "center",
  //   fontSize: 50,
  //   color: theme.palette.primary.main,
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: 35,
  //   },
  // },
  btnAudio: {
    color: theme.custom.color.white,
    background: "#ffffff0f",
    borderRadius: "50%",
    width: 50,
    height: 60,
    position: "absolute",
    top: "calc(50% - 30px)",
    left: "calc(50% - 25px)",
  },
  // boxRemaining: {
  //   display: "flex",
  //   justifyContent: "center",
  //   flexDirection: "column",
  //   [theme.breakpoints.up("xs")]: {
  //     alignItems: "center",
  //     marginTop: theme.spacing(3),
  //     marginBottom: theme.spacing(8),
  //   },
  // },
  buttonHere: {
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up("sm")]: {
    roadmapLabelContainer: {
      "& div:nth-child(1)": {
        width: "55%",
        left: "60%",
        top: "20%",
      },
      "& div:nth-child(2)": {
        width: "40%",
        left: "-30%",
        top: "45%",
      },
      "& div:nth-child(3)": {
        width: "40%",
        left: "5%",
        top: "90%",
      },
      "& div:nth-child(4)": {
        width: "35%",
        left: "92%",
        top: "55%",
      },
    },
  },
  variety: {
    display: "flex",
  },
  DiscordBox: {
    background: "rgba(217, 4, 4, 0.5)",
    padding: theme.spacing(0.9),
    display: "block",
    textAlign: "center",
    zIndex: 1,
    position: "fixed",
    bottom: "15px",
    right: theme.spacing(2.2),
    transition: ".2s ease",
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      right: 0,
      bottom: "20px",
    },
    "&:hover": {
      background: theme.palette.primary.main,
      transition: ".3s ease",
      transform: "scale(1.5)",
      right: theme.spacing(5.5),
    },
  },
  discordImg: {
    height: 40,
    width: 40,
  },
  textLinks: {
    marginBottom: theme.spacing(0.5),
    fontSize: 10,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  [theme.breakpoints.up("lg")]: {
    roadmapLabelContainer: {
      "& div:nth-child(1)": {
        width: "40%",
        left: "60%",
        top: "25%",
      },
      "& div:nth-child(2)": {
        width: "35%",
        left: "-25%",
        top: "50%",
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    hero: {
      "& > div": {
        padding: "2rem 1rem",
        "&:first-child": {
          minHeight: "auto",
        },
      },
    },
    landingInfo: {
      textAlign: "center",
      alignItems: "baseline",
    },
    roadmap: {
      "& > div": {
        textAlign: "left",
      },
      "& > div > div:nth-child(2)": {
        marginLeft: "20%",
      },
    },
    roadmapLabelContainer: {
      "& div:nth-child(1), div:nth-child(2), div:nth-child(3), div:nth-child(4)": {
        textAlign: "left",
        width: "35%",
        left: "38%",
      },
      "& div:nth-child(1)": {
        top: "20%",
      },
      "& div:nth-child(2)": {
        top: "42%",
      },
      "& div:nth-child(3)": {
        top: "64%",
      },
      "& div:nth-child(4)": {
        top: "85%",
      },
    },
  },
  [theme.breakpoints.down("xs")]: {
    roadmap: {
      "& > div > div:nth-child(2)": {
        marginLeft: "0",
      },
    },
    variety: {
      flexDirection: "column",
      marginLeft: theme.spacing(1),
    },
    varietyCards: {
      marginTop: theme.spacing(2),
    },
    roadmapLabelContainer: {
      "& div:nth-child(1), div:nth-child(2), div:nth-child(3), div:nth-child(4)": {
        width: "55%",
      },
    },
  },
  popup: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(30),
      height: "30%",
      "& h2": {
        fontSize: "15px !important",
      },
    },
  },
  // boxCounter: {
  //   display: "flex",
  //   justifyContent: "space-around",
  //   alignItems: "center",
  //   marginTop: theme.spacing(1),
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "column",
  //     margin: 0,
  //   },
  // },
}));
const Landing = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: session, isLoggedIn } = useSession();
  const dialogs = useDialogs();
  const [activateAudio, setActivateAudio] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const rulesPerPage = isMobile ? 1 : 3;
  const [visible, setIsVisible] = useState(true);

  const imagesCode = {
    ellenDegeneresCommon: "1NwTc2Q6HvEtoXCfEdDEeCeKKLd6Jght4",
    leonardoDicaprioRare: "1HZD3DMZy30Dq-XcJltiO6JrGZiqcvTy8",
    loganPaulEpic: "1KNLEb09q36zwjiDoYjdkyZgRtEdBdiQM",
    travisScottLegendary: "194eo8PSG6FOzutN7RyT3VHZjc_o8rjWJ",
    joeBidenLegendary: "1LAfOqNuj0QroRN12nOhomdMlBKdngZaf",
    elonMuskLegendary: "1jFk7YM2syMElEDO9NldEqfaWb_WK7_qq",
    duaLipaLegendary: "1JoKb89jTxmUoSjbfTqter3W4DtaRRzK4",
    lebronJamesLegendary: "1Ecfqp5YQeqn3V7jtdTNoqIdSSwEFTHn2",
    zendayaLegendary: "1KEI7b3WbCIpBbQEUTh3bZIPDu-7Wh93t",
  };
  const gameRules = [
    "GAMES ARE BETWEEN 2 PLAYERS, EACH ONE WITH THEIR 3 CELEBRITIES (DECK).",
    "CELEBRITIES ARE DIVIDED INTO GENRES AND EACH GENRE HAS ITS OWN CONSUMABLES.",
    "EACH PLAYER HAS A LIMIT OF 30 SECONDS TO MAKE HIS MOVE.",
    "CELEBRITIES HAVE A NUMBER OF FOLLOWERS ASSIGNED TO THEM.",
    "CELEBRITIES GAME STATS: LIFE-ATTACK-DEFENSE-COMBAT INITIATIVE.",
    "THERE ARE 4 DIFFERENT TYPES OF BOARDS. HOLLYWOOD, SPORTS ARENA, STAGE, WHITE HOUSE.",
  ];

  const scrollButton = () => {
    const heightToHideFrom = 3900;
    const windowscroll = document.getElementById("__next").scrollTop;
    if (windowscroll > heightToHideFrom) {
      setIsVisible(false);
    } else {
      visible && setIsVisible(true);
    }
  };

  useEffect(() => {
    document.getElementById("__next").addEventListener("scroll", scrollButton);
    return () => document.getElementById("__next").removeEventListener("scroll", scrollButton);
  }, []);

  const getImage = (code) => `https://drive.google.com/uc?export=view&id=${code}`;

  const {
    data,
    loading,
  } = useResource(isLoggedIn && APIEndpoints.USER.DECKS.PLAYABLES);

  const goToMyRooster = () => {
    router.push(routes.MY_ROSTER);
  };
  const launchGame = async () => {
    if (!session) {
      router.push(routes.SIGN_IN);
    }
    if (!loading) {
      if (!data.data.length) {
        dialogs.create({
          title: "You don't have any deck to start the game",
          subtitle: (
            <Typography variant="body2">
              if you don't know how to build one click
              <a href="https://www.youtube.com/watch?v=Y_bsIMKzX-M" className={classes.buttonHere} target="_blank" rel="noreferrer"> here</a>
            </Typography>
          ),
          onContinue: goToMyRooster,
          className: classes.popup,
        });
      } else {
        window.open(`${process.env.NEXT_PUBLIC_GAME_URL}?jwt=${session.accessToken}`, "_blank");
      }
    }
  };

  useEffect(() => {
    document.querySelector("#howToPlayVideo").playbackRate = 1.5;
  }, []);
  return (
    <Container className={classes.hero}>
      <Box display="flex" flexDirection="column">
        {/* <Box className={classes.boxCounter}>
          <Box className={classes.countdownText}>
            <Typography variant="h4">
              STORE OPEN!
            </Typography>
            <Typography variant="h6">
              Get your first edition exclusive cards now!
            </Typography>
            <Box pt={2}>
              <Link passHref href={routes.STORE}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  <Typography variant="h5">
                    {t("STORE")}
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Box>
          <Box className={classes.boxRemaining}>
            <Typography style={{ color: "white" }} variant="h5">
              Remaining Time:
            </Typography>
            <Box color="primary" className={classes.countdownNumbers}>
              <Countdown date={new Date("2022-01-01T00:00-0000")} />
            </Box>
            <Box display="flex" justifyContent="center" style={{ color: "white" }}>
              <Typography style={{ margin: 10 }} variant="body2">
                Days
              </Typography>
              <Typography style={{ margin: 10 }} variant="body2">
                Hours
              </Typography>
              <Typography style={{ margin: 10 }} variant="body2">
                Minutes
              </Typography>
              <Typography style={{ margin: 10 }} variant="body2">
                Seconds
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Grid
          container
          flex={1}
          alignItems="center"
          className={classes.landingInfo}
        >
          <Grid item xs={12} md={4}>
            <Box position="relative" zIndex={5}>
              <Typography variant="h1" paragraph>
                CELEBRITY SMACK DOWN
              </Typography>
              <Typography variant="h5" paragraph>
                A strategic NFT card game that puts your favorite
                celebrities in a battle to cancel each other.
              </Typography>
              <Button
                size="large"
                color="primary"
                variant="contained"
                style={{ padding: "1.5rem 3rem", marginTop: 16 }}
                onClick={launchGame}
              >
                <Typography variant="h4">{t("PLAY_NOW")}</Typography>
              </Button>
            </Box>
          </Grid>
          {visible && (
            <Box id="discord" onScroll={scrollButton} className={classes.DiscordBox}>
              <a target="_blank" rel="noreferrer" href="https://discord.com/invite/5VEe4T52hC">
                <Typography style={{ display: isMobile ? "none" : "block" }} className={classes.textLinks}>
                  Need help?
                </Typography>
                <img alt="Asset not found" src={discordIcon.src} className={classes.discordImg} />
                <Typography style={{ display: isMobile ? "none" : "block", fontSize: 10 }}>
                  Join our discord
                </Typography>
              </a>
            </Box>
          )}
          <Grid item xs={12} md={8}>
            <Box
              display="flex"
              position="relative"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              style={{ height: isMobile ? "400px" : "100%" }}
              width={1}
              height={1}
            >
              <video
                id="landingVideo"
                src={landingVideo}
                autoPlay
                loop
                playsInline
                width="100%"
                height="100%"
                controls={false}
                muted
                // style={{ mixBlendMode: "screen" }}
              />
              <Button
                className={classes.btnAudio}
                onClick={() => {
                  const videoElem = document.getElementById("landingVideo");
                  videoElem.muted = !videoElem.muted;
                  setActivateAudio((current) => !current);
                }}
              >
                {!activateAudio ? <VolumeOff /> : <VolumeUp />}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box pt={4} pb={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Box mt={2}>
            <Typography variant="h2" paragraph>
              GET SUPER CELEBRITIES PACK
            </Typography>
            <Box maxWidth="50%" margin="auto">
              <Typography variant="h5" paragraph>
                BE THE FIRST TO BUY THESE CELEBRITY PACKS. THEY WILL BE ON SALE FOR A LIMITED TIME.
              </Typography>
            </Box>
            <Grid container justifyContent="center">
              <InView>
                {({ inView, ref }) => (
                  <Box
                    ref={ref}
                    display="flex"
                    justifyContent="center"
                    position="relative"
                    gridGap={8}
                    mt={4}
                    mb={8}
                    className={classes.celebritiesPacksContainer}
                  >
                    <Slide in={inView} direction="right" style={{ transitionDelay: 800 }}>
                      <img
                        alt="Asset not found"
                        src={getImage(imagesCode.ellenDegeneresCommon)}
                        width="20%"
                      />
                    </Slide>
                    <Slide in={inView} direction="right" style={{ transitionDelay: 600 }}>
                      <img
                        alt="Asset not found"
                        src={getImage(imagesCode.leonardoDicaprioRare)}
                        width="20%"
                      />
                    </Slide>
                    <Slide in={inView} direction="right" style={{ transitionDelay: 400 }}>
                      <img
                        alt="Asset not found"
                        src={getImage(imagesCode.loganPaulEpic)}
                        width="20%"
                      />
                    </Slide>
                    <Slide in={inView} direction="right" style={{ transitionDelay: 200 }}>
                      <img
                        alt="Asset not found"
                        src={getImage(imagesCode.travisScottLegendary)}
                        width="20%"
                      />
                    </Slide>
                  </Box>
                )}
              </InView>
            </Grid>
            <Button
              size="large"
              color="primary"
              variant="contained"
              style={{ padding: "1.5rem 3rem" }}
              onClick={() => router.push(routes.STORE)}
            >
              <Typography variant="h4">{t("BUY_NOW")}</Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
      <Box pt={4} pb={4}>
        <Grid container justifyContent="center" alignItems="center">
          <Box mt={2} style={{ maxWidth: "100%" }}>
            <Typography variant="h2" paragraph>
              LEARN HOW TO PLAY CSD
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={10}>
                <Box mt={4}>
                  <video
                    id="howToPlayVideo"
                    src={howToPlayVideo}
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls={false}
                    width="100%"
                  >
                    Your browser doesn&apos;t support HTML5 video
                  </video>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box mt={4}>
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={false}
                    showArrows
                  >
                    {[...new Array(Math.round(gameRules.length / rulesPerPage))].map((v, i) => (
                      <Grid key={i} container spacing={2} style={{ height: "100%" }}>
                        {gameRules.slice(rulesPerPage * i, rulesPerPage * (i + 1))
                          .map((item, index) => (
                            <Grid key={index} item xs={12 / rulesPerPage}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                padding={2}
                                height={isMobile ? 100 : 175}
                                style={{ background: "#fff" }}
                              >
                                <Typography variant="h6" style={{ color: "#000" }}>
                                  {item}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    ))}
                  </Carousel>
                  <Box mt={8}>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      style={{ padding: "1.5rem 3rem" }}
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                      <Typography variant="h4">{t("WHITEPAPER")}</Typography>
                    </Button>
                    <Popover
                      id="simple-popover"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box>
                        <MenuItem
                          onClick={() => window.open("https://drive.google.com/file/d/1OxmKXMc0I9iE91D4xTihUgOtHW1uszP8/view?usp=sharing", "_blank")}
                        >
                          <Typography variant="h6">ENGLISH</Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => window.open("https://drive.google.com/file/d/1cZvgXXEDbbnRLjo3b4aHd-2CqFBE59q1/view?usp=sharing", "_blank")}
                        >
                          <Typography variant="h6">SPANISH</Typography>
                        </MenuItem>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      
      <Box pt={4} pb={4}>
        <Box mt={2} width={1}>
          <Typography variant="h2" paragraph>
            CHARACTERS VARIETY
          </Typography>
          <Box my={4}>
            <Grid container>
              <Box className={classes.variety}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  boxSizing="border-box"
                  className={classes.varietyCards}
                >
                  <img
                    alt="Asset not found"
                    src={getImage(imagesCode.joeBidenLegendary)}
                    width="75%"
                  />
                  <Typography variant="h4">Politician</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  boxSizing="border-box"
                  className={classes.varietyCards}
                >
                  <img
                    alt="Asset not found"
                    src={getImage(imagesCode.elonMuskLegendary)}
                    width="75%"
                  />
                  <Typography variant="h4">Influencer</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  boxSizing="border-box"
                  className={classes.varietyCards}
                >
                  <img
                    alt="Asset not found"
                    src={getImage(imagesCode.duaLipaLegendary)}
                    width="75%"
                  />
                  <Typography variant="h4">Musician</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  boxSizing="border-box"
                  className={classes.varietyCards}
                >
                  <img
                    alt="Asset not found"
                    src={getImage(imagesCode.lebronJamesLegendary)}
                    width="75%"
                  />
                  <Typography variant="h4">Athlete</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  boxSizing="border-box"
                  className={classes.varietyCards}
                >
                  <img
                    alt="Asset not found"
                    src={getImage(imagesCode.zendayaLegendary)}
                    width="75%"
                  />
                  <Typography variant="h4">Actors</Typography>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Button
            size="large"
            color="primary"
            variant="contained"
            style={{ padding: "1.5rem 3rem" }}
            onClick={() => router.push(routes.STORE)}
          >
            <Typography variant="h4">{t("START_NOW")}</Typography>
          </Button>
        </Box>
      </Box>
      
      {/* Latest news */}
      
      <Box pt="2rem" className={classes.latest}>
        <Grid container justifyContent="center" alignItems="center">
          <Box width={1} textAlign="center">
            <Typography variant="h2" paragraph>
              LATEST NEWS
            </Typography>
          </Box>
          <Grid item xs={12} md={8} style={{ position: "relative" }}>
            <img src={BonusSystem.src} alt="roadmap asset" width={isMobile ? "100%" : "80%"} />
          </Grid>
        </Grid>
      </Box>

      {/* roadmap */}
      <Box pt="2rem" className={classes.roadmap}>
        <Grid container justifyContent="center" alignItems="center">
          <Box mb={8} width={1} textAlign="center">
            <Typography variant="h2" paragraph>
              ROADMAP
            </Typography>
          </Box>
          <Grid item xs={12} md={8} style={{ position: "relative" }}>
            <picture>
              <source srcSet={roadmapMobile.src} media={theme.breakpoints.down("sm").replace("@media ", "")} />
              <img src={roadmapDesktop.src} alt="roadmap asset" width={isMobile ? "40%" : "80%"} />
            </picture>
            <Box className={classes.roadmapLabelContainer} textAlign="left">
              <Box className={classes.roadmapLabel}>
                <Typography variant="h5" color="primary">STAGE 1</Typography>
                <Typography variant="h6">JULY/AUGUST</Typography>
                <label>
                  Project Concept | Card game mechanics, characters, rarity and concepts created |
                  Official name Celebrity Smack Down | Home page design
                </label>
              </Box>
              <Box className={classes.roadmapLabel} textAlign="right">
                <Typography variant="h5" color="primary">STAGE 2</Typography>
                <Typography variant="h6">SEPTEMBER/OCTOBER</Typography>
                <label>
                  Official website | Roadmap | Marketing team | Social networks | Video gameplay |
                  NFTs associated with a celebrity&apos;s tweet |
                  Launch of NFTs on Nervos Mainnet |
                  Creation of cards, consumables and arenas for gameplay
                </label>
              </Box>
              <Box className={classes.roadmapLabel} textAlign="right">
                <Typography variant="h5" color="primary">STAGE 3</Typography>
                <Typography variant="h6">NOVEMBER/DECEMBER</Typography>
                <label>
                  Whitepaper | Pre-sale | CSD Game - Alpha | Whitelist | Second pre-sale |
                  Marketplace | Play to earn system
                </label>
              </Box>
              <Box className={classes.roadmapLabel} textAlign="left">
                <Typography variant="h5" color="primary">STAGE 4</Typography>
                <Typography variant="h6">EARLY 2022</Typography>
                <label>
                  Beta game launch | Official telegram | More celebrities | Land system (Board) |
                  Minting power ups | Consumables in marketplace | Scholarships
                </label>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.crowdContainer} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default withMainLayout(Landing);
